import React from 'react'
import Layout from '../components/Layout'

const NotFoundPage = () => {
  if (typeof window !== 'undefined') {
    window.scrollTo(0, 0)
    window.scrollTo(0, 10)
  }
  return (
    <Layout>
      <section className="section">
        <div className="container">
          <div className="content">
            <div className="has-text-centered" style={{ paddingTop: 100 }}>
              <h1>PAGE NOT FOUND</h1>
              <p style={{ margin: "2rem 0" }}>You just hit a route that doesn&#39;t exist... the sadness.</p>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default NotFoundPage
